<template>
  <div>
    <div class="cart-card" v-for="(shop, s_index) in list" :key="s_index">
      <!-- list -->
      <div @click.stop>
        <van-swipe-cell
          ref="swipeDle"
          stop-propagation>
          <div class="shop-list">

          <van-icon
            name="delete-o"
            class="delete-icon"
            size="16"
            color="#C6C6C6"
            @click="isDelete(s_index, -1)"
            @click.stop
          />


            <div class="shop-list-content">
              <!-- 单选按钮 -->
              <div
                class="select-all"
                :class="{ 'is-show-back': shop.is_select * 1 == 1 }"
                @click="onSelectRadio(s_index)"
                @click.stop
              >
                <van-icon
                  name="success"
                  style="display: none"
                  :class="{ 'is-show-icon': shop.is_select * 1 == 1 }"
                  color="#fff"
                />
              </div>
              <!-- checkbox -->
              <div class="content-text">
                <!-- img -->
                <div class="list-content-img">
                  <!-- 消费金
                <img src="" alt="" /> -->
                  <!-- goods-img -->
                  <img :src="shop.goods_img" class="goods-img" />
                </div>
                <!-- content -->
                <div class="goods-info">
                  <div class="goods-title">
                    {{ shop.goods_name }}
                  </div>
                  <span class="goods-size">
                    <span>{{ shop.item_name }}</span>
                  </span>
                  <div class="goods-price" @click.stop>
                    <div class="present-price">
                      <span class="small">￥</span>
                      <span class="integer">{{
                        parseInt(shop.spec_price)
                      }}</span>
                      <span class="small">{{ twoNum(shop.spec_price) }}</span>
                    </div>
                    <span class="original-price">
                      <span>￥</span>
                      <span>
                        {{ parseInt(shop.spec_pricing) }}
                      </span>
                      <span>
                        {{ twoNum(shop.spec_pricing) }}
                      </span>
                    </span>
                    <van-stepper
                      v-model="shop.number"
                      mix="1"
                      :max="shop.spec_stock"
                      button-size="26"
                      @change="setGoodsNum(s_index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #right>
            <van-button
              square
              text="删除"
              type="danger"
              class="delete-button"
              @click="confirmBtnId(shop.id, g_index)"
            />
          </template>
        </van-swipe-cell>
      </div>
    </div>
    <van-popup v-model="showPopup">
      <span class="is-del">是否确认删除</span>
      <div class="is-button">
        <div class="button">
          <van-button type="danger" plain round block @click="cancelBtn">
            取消
          </van-button>
        </div>
        <div class="button">
          <van-button type="danger" round block @click="confirmBtn">
            确认
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import {
//   delShopping,
//   getShoppingGoods,
//   saveShoppingNum,
//   setSelect,
// } from "@/api/shopping";

import { robGoods,robDel,robSelect,robSave,} from "@/api/rob.js";


import tools from "@/common/js/tools";
import { Toast } from "vant";

export default {
  name: "cart-list",
  data() {
    return {
      value: 1,
      selectAll: false,
      num: 1,
      result: [],
      list: [],
      isShowAll: false,
      isShowRadio: false,
      showPopup: false, //弹出层控制
      delIds: [],
    };
  },
  mounted() {
    this.getBuyList();
  },
  methods: {

    onSelectAll() {
      this.isShowAll = !this.isShowAll;
    },
    async onSelectRadio(sIndex) {
      //设置选中状态
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      let is_select = this.list[sIndex].is_select * 1 == 0 ? 1 : 0;
      this.list[sIndex].is_select = is_select;
      this.setSelect(this.list[sIndex].id,is_select);
      this.isAjax = false;
    },
    setAll() {
      // 设置全选
      let shoppingIds = [];
      for (let g_i in this.list) {
        this.list[g_i].is_select = 1;
        shoppingIds[shoppingIds.length] = this.list[g_i].id;
      }
      shoppingIds = shoppingIds.join(",");
      this.setSelect(shoppingIds, 1);
    },
    setTotalPrices() {
      // 计算总价
      let totalPrices = 0;
      for (let i in this.list) {
        if (this.list[i].is_select * 1 == 1) {
          totalPrices +=
            this.list[i].spec_price *
            this.list[i].number;
        }
      }
      this.$emit("setTotalPrices", {
        totalPrices: totalPrices,
        totalNum: this.list.length,
      });
    },
    isDelete(sIndex) {
      this.showPopup = true;
      this.delIds = [];
      for (let i in this.list) {
        this.delIds[this.delIds.length] =
          this.list[sIndex].id;
      }
    },
    cancelBtn() {
      this.showPopup = false;
    },
    confirmBtnId(delId, g_index) {
      this.delIds = [delId];
      this.confirmBtn();
    },
    async confirmBtn() {
      //执行删除
      if (this.delIds.length <= 0) {
        Toast.fail("删除商品信息错误");
        this.showPopup = false;
        return false;
      }
      let shoppingIds = this.delIds.join(",");
      const ret = await robDel({ shopping_ids: shoppingIds });
      if (ret.code * 1 == 1) {
        Toast.success("删除成功");
        this.getBuyList();

      }
      tools.delShoppingNum();
      this.showPopup = false;
    },
    async setSelect(shoppingIds, isSelect) {
      //选中请求
      const ret = await robSelect({
        shopping_ids: shoppingIds,
        is_select: isSelect,
      });
      this.setTotalPrices();
    },
    async setGoodsNum(sIndex) {
      //需改购物车数量
      const sId = this.list[sIndex].id;
      let number = this.list[sIndex].number;
      if (number > this.list[sIndex].spec_stock) {
        number = this.list[sIndex].spec_stock;
        this.list[sIndex].number = number;
      }
      const ret = await robSave({ shopping_id: sId, number: number });
      if (ret.code * 1 == 1) {
        //购物车数量增加成功
        this.setTotalPrices();
      }
    },
    twoNum(num) {
      // 获取两位小数
      let price = String(num).split(".")[1];
      price === undefined ? (price = ".00") : (price = `.${price}`);
      return price;
    },

    getBuyList() {
      //获取购物车数据
      robGoods().then((ret)=>{
        if (ret.code * 1 == 1) {
          this.list = ret.data;
          this.setTotalPrices();
        }
      })
    },

    // 提交订单
    submitOrder() {
      let shoppingIds = [];
      for (let i in this.list) {
        if (this.list[i].is_select * 1 == 1) {
          shoppingIds[shoppingIds.length] = this.list[i].id;
        }
      }
      if (shoppingIds.length <= 0) {
        Toast.fail("暂无选中的商品");
        return false;
      }
      shoppingIds = shoppingIds.join(",");
      this.$router.push({
        name: "create-order",
        query: { shoppingIds: shoppingIds,orderType:90},
      });
    },
  },
};
</script>

<style lang="less" scoped>
.is-del {
  font-size: 16px;
}
.van-swipe-cell {
  margin-top: 20px;
  &:nth-child(1) {
    margin-top: 6px;
  }
}
// 复选框样式
// 全选
.select-all {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 1px solid #cecfd2;
  border-radius: 50%;
}

.is-show-back {
  border: 0;
  background-color: #ed3724;
  transition: all 0.3s;
}

.is-show-icon {
  display: block !important;
  transition: all 0.3s;
}

// 单选
// 商品信息样式
.shop-list {
  position: relative;
  .delete-icon {
    position: absolute;
    top: 0;
    right: 0;
  }
  .shop-list-content {
    display: flex;
    align-items: center;

    .content-text {
      display: flex;
      align-items: center;
    }
  }

  .list-content-img {
    width: 90px;
    margin: 0 12px;
    border-radius: 6px;
    overflow: hidden;
    .goods-img {
      display: block;
      width: 90px;
      height: 90px;
    }
  }
}

.icon-delete {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

//组件样式
.cart-card {
  margin-top: 12px;
  background-color: #fff;
  border-radius: 16px;
  padding: 10px 10px 20px 10px;
}

.delete-button {
  height: 100%;
}


.goods-info {
  width: 234px;

  .goods-title {
    width: 90%;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.goods-size {
  display: inline-block;
  width: auto;
  font-size: 14px;
  border-radius: 4px;
  color: #6f6f6f;
  padding: 4px;
  background-color: #f2f2f2;
  margin: 6px 0;
}

.goods-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .present-price {
    color: #ed301d;
    font-size: 14px;
  }
  .integer {
    font-weight: 600;
    font-size: 18px;
  }
  .original-price {
    color: #707070;
    text-decoration: line-through;
    font-size: 12px;
  }
}

// 弹出层
.van-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 14px;
  width: 320px;
  height: 190px;
  border-radius: 16px;
  box-sizing: border-box;

  .is-button {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .button {
      width: 116px;
    }
  }
}
</style>
