import request from './ajax'
// 抢购
const orderUrl = '/api/rob/'
// 获取商品列表
export const robList = (data) => request(orderUrl + 'list', 'post', { ...data })
// 获取商品详情
export const robInfo = (data) => request(orderUrl + 'info', 'post', { ...data })
// 获取商品规格
export const robSpec = (data) => request(orderUrl + 'spec', 'post', { ...data })
// 添加购物车
export const robDdd = (data) => request(orderUrl + 'add', 'post', { ...data })

// 购物车商品列表
export const robGoods = (data) => request(orderUrl + 'goods', 'post', { ...data })
// 获取总价
export const robTotal = (data) => request(orderUrl + 'total', 'post', { ...data })
// 删除购物车
export const robDel = (data) => request(orderUrl + 'del', 'post', { ...data })
// 设置购物车选中
export const robSelect = (data) => request(orderUrl + 'select', 'post', { ...data })
// 设置购物车数量
export const robSave = (data) => request(orderUrl + 'save', 'post', { ...data })
// 下单
export const robCreate = (data) => request(orderUrl + 'create', 'post', { ...data })

